<template>
    <div class="container mx-auto py-20">
      <h5
        class="font-bold uppercase text-2xl tracking-tight text-webmoney text-center mb-20"
      >
        {{ $t("ext.trusted.trusted_and_loved") }}
      </h5>
      <div class="flex flex-col lg:flex-row justify-around">
        <img
          class="filter grayscale object-none"
          src="@/assets/images/memo-logo.svg"
          alt="memo"
        />
        <img
          class="filter grayscale object-none"
          src="@/assets/images/ndx-logo.png"
          alt="ndx"
        />
        <img
          class="filter grayscale object-none"
          :src="
            require(`@/assets/images/webmoney-events-${$i18n.locale}.svg`)
          "
          alt="events"
        />
      </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

</style>
